import {required, minLength} from 'vuelidate/lib/validators';

const // Custom validators
    isUsername = value => (/^[a-z0-9_]+$/i.test(value)),
    isDescription = value => (/^[a-z0-9\s.,;:_()-]+$/i.test(value));

export default {
  ftpAccount: {
    Username: {
      required,
      isUsername,
    },

    Password: {
      required,
      minLength: minLength(5),
    },

    PasswordConfirm: {
      required,
      minLength: minLength(5),
      matchPassword: (passwordConfirm, form) => {
        return form.Password === passwordConfirm
      },
    },

    Description: {
      required: false,
      isDescription,
    }
  },
}