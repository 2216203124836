import {bus} from '@/helpers/bus';

import Utils from '@/helpers/utils';
import Dh from '@/helpers/date.helper';

import Validations from './validation';
import Errors from './Errors';

import FtpService from '@/services/ftp.service';
const service = new FtpService();

export default {
  name: 'FtpAccountsList',

  data() {
    return {
      ftpAccounts: [],
      ftpAccount: {
        Username: '',
        Password: '',
        PasswordConfirm: '',
        Description: '',
      },

      createModal: false,
      editModal: false,
      editPasswordModal: false,
      deleteModal: false,
      isLoading: false,

      searchTerm: '',
      perPage: 25,
      totalRows: 0,
      currentPage: 1
    }
  },

  validations: Validations,

  computed: {
    ...Errors,
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async create() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;

      const res = await service.createAccount(this.ftpAccount);
      if (res && !res.error) {
        this.closeModal();

        if (res && !res.error) {
          this.ftpAccounts.unshift(res.data.ftpAccount);
        }
      }

      this.isLoading = false;
    },

    async edit() {
      await this.$v.$touch();

      const isInvalid = this.$v.ftpAccount.Username.$invalid || this.$v.ftpAccount.Description.$invalid;
      if (isInvalid) return;

      this.isLoading = true;

      const ftpAccountIdx = this.ftpAccounts.findIndex(el => el._id === this.ftpAccount?._id);

      const res = await service.editAccount(this.ftpAccount);
      if (res && !res.error) {
        if (ftpAccountIdx > -1) {
          this.ftpAccounts[ftpAccountIdx] = {
            ...this.ftpAccount,
            updatedAt: new Date(),
          };
        }

        this.closeModal();
      }

      this.isLoading = false;
    },

    async editPassword() {
      await this.$v.$touch();

      const isInvalid = this.$v.ftpAccount.Password.$invalid || this.$v.ftpAccount.PasswordConfirm.$invalid;
      if (isInvalid) return;

      this.isLoading = true;

      const res = await service.resetAccountPassword(
          this.ftpAccount.Username,
          this.ftpAccount.Password,
          this.ftpAccount.PasswordConfirm
      );

      if (res && !res.error) {
        this.closeModal();
      }

      this.isLoading = false;
    },

    async remove() {
      this.isLoading = true;

      const ftpAccountIdx = this.ftpAccounts.findIndex(el => el._id === this.ftpAccount._id);

      const res = await service.deleteAccount(this.ftpAccount._id);
      if (res && !res.error) {
        this.closeModal();

        if (ftpAccountIdx > -1) {
          this.ftpAccounts.splice(ftpAccountIdx, 1);
        }
      }

      this.isLoading = false;
    },

    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        search: this.searchTerm,
      }

      const res = await service.listAccounts(this.perPage, skip, filters);
      if (res && !res.error) {
        this.ftpAccounts = res.data.ftpAccounts;
        this.totalRows = res.data.count;
      }
    },

    openModal(modal, ftpAccount) {
      this[modal] = true;

      if (ftpAccount) {
        const modals = {
          editModal: {...ftpAccount},
          editPasswordModal: {...ftpAccount, Password: '', PasswordConfirm: ''},
          deleteModal: {...ftpAccount},
        }

        this.ftpAccount = modals[modal];
      }
    },

    closeModal() {
      this.createModal = false;
      this.editModal = false;
      this.editPasswordModal = false;
      this.deleteModal = false;
      this.isLoading = false;

      this.resetFtpAccount();
      this.$v.$reset();
    },

    resetFtpAccount() {
      this.ftpAccount = {
        Username: '',
        Password: '',
        PasswordConfirm: '',
        Description: '',
      }
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    highlight(text) {
      return Utils.HighlightText(text, this.searchTerm);
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    },
  },

  watch: {
    async perPage() {
      await this.list();
    },

    async searchTerm() {
      await this.list();
    },
  },

  filters: {
    formatDate(value) {
      if (value) return Dh.formatDate(value);
      return 'N/A'
    },
  }
}