export default {
  UsernameError() {
    if (!this.$v.ftpAccount.Username.$anyError) return null;
    else if (!this.$v.ftpAccount.Username.required) return "required_field";
    else if (this.$v.ftpAccount.Username.$invalid) return "invalid_field";
    else return null;
  },

  PasswordError() {
    if (!this.$v.ftpAccount.Password.$anyError) return null;
    else if (!this.$v.ftpAccount.Password.required) return "required_field";
    else if (this.$v.ftpAccount.Password.$invalid) return "invalid_field";
    else return null;
  },

  PasswordConfirmError() {
    if (!this.$v.ftpAccount.PasswordConfirm.$anyError) return null;
    else if (!this.$v.ftpAccount.PasswordConfirm.required) return "required_field";
    else if (this.$v.ftpAccount.PasswordConfirm.$invalid) return "invalid_field";
    else return null;
  },

  DescriptionError() {
    if (!this.$v.ftpAccount.Description.$anyError) return null;
    else if (!this.$v.ftpAccount.Description.required) return "required_field";
    else if (this.$v.ftpAccount.Description.$invalid) return "invalid_field";
    else return null;
  },
};